import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../../generic/Form/Fields/Input";
import { FieldMappingInterface, FieldMappingsApiData, FieldMappingsInterface, ReportInterface, FieldMappingDetailsInterface, FieldMappingKeyIdentifierInterface } from "../../../interfaces/ReportInterface";
import ProviderSelect from "./ProviderSelect";
import CheckboxField from "../../generic/Form/Fields/Checkbox";
import { ApiService } from "../../../services/generic/apiService";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface Props {
  report?: ReportInterface | null;
  onSubmit: (data: any) => void;
}

interface CheckboxStates {
  [key: string]: boolean | undefined;
}

interface FieldVisibilityStates {
  [entity: string]: {
    [field: string]: boolean;
  };
}

const ReportFormEdit: React.FC<Props> = ({ onSubmit, report }) => {
  const { t: tReport } = useTranslation('report');
  const apiService = new ApiService<FieldMappingsInterface, FieldMappingsInterface>('/reports/fields');
  const [fieldMappingsData, setFieldMappingsData] = useState<FieldMappingsInterface>({});
  const [initialFieldMapping, setInitialFieldMapping] = useState<FieldMappingsInterface>({});
  const [checkedEntities, setCheckedEntities] = useState<{ [key: string]: boolean | undefined }>({});
  const [fieldVisibility, setFieldVisibility] = useState<{ [entity: string]: { [field: string]: boolean | undefined } }>({});

  useEffect(() => {
    const fetchFieldMappings = async () => {
      const data: FieldMappingsInterface = await apiService.getObject();
      setFieldMappingsData(data);
      const initialCheckboxState: CheckboxStates = {};
      const initialVisibilityState: FieldVisibilityStates = {};

      Object.keys(data).forEach(entity => {
        initialCheckboxState[entity] = (report?.fieldMappings && report.fieldMappings[entity]) ? true : false;
        initialVisibilityState[entity] = {};
        if (report && report.fieldMappings[entity] && report.fieldMappings[entity].fields) {
          Object.entries(report.fieldMappings[entity].fields).map(([field, value]) => {
            initialVisibilityState[entity][field] = value?.name ? true : false;
          });
        }
      });

      setCheckedEntities(initialCheckboxState);
      setFieldVisibility(initialVisibilityState);

      setInitialFieldMapping(
        Object.keys(data).reduce((acc: FieldMappingsInterface, key) => {
          const fields = Object.entries(data[key].fields).reduce((fieldsAcc: FieldMappingDetailsInterface, [fieldName, fieldDetails]) => {
            fieldsAcc[fieldName] = {
              name: '',
              type: fieldDetails.type,
              required: fieldDetails.required
            };
            return fieldsAcc;
          }, {});

          acc[key] = {
            fields,
            keyIdentifier: {
              name: '',
              field: data[key].keyIdentifier.field,
              entity: data[key].keyIdentifier.entity
            }
          };
          return acc;
        }, {})
      );
    };


    fetchFieldMappings();
  }, []);

  const methods = useForm<ReportInterface>({
    defaultValues: {
      provider: report?.provider ?? "",
      label: report?.label ?? "",
      fieldMappings: report?.fieldMappings ?? initialFieldMapping
    }
  });

  React.useEffect(() => {
    methods.reset({
      provider: report?.provider ?? "",
      label: report?.label ?? "",
      fieldMappings: report?.fieldMappings ?? initialFieldMapping
    });
  }, [report, methods.reset, initialFieldMapping]);

  const handleCheckboxChange = (entityName: string) => {
    setCheckedEntities(prev => ({ ...prev, [entityName]: !prev[entityName] }));
  };

  const handleFieldVisibilityChange = (entityName: string, fieldName: string) => {
    setFieldVisibility(prev => ({
      ...prev,
      [entityName]: {
        ...prev[entityName],
        [fieldName]: !prev[entityName][fieldName]
      }
    }));
  };

  const handleFormSubmit = methods.handleSubmit((formData) => {
    formData.fieldMappings = Object.keys(formData.fieldMappings).reduce((acc: FieldMappingsInterface, key) => {
      if (checkedEntities[key] === true) {
        acc[key] = formData.fieldMappings[key];
      }
      return acc;
    }, {})
    onSubmit(formData);  // Appel à onSubmit avec les données filtrées
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit} className="form-grid">
        <ProviderSelect
          key="provider"
          name="provider"
          label="Fournisseur"
          validationRules={{ required: "Ce champ est requis" }}
          defaultValue={report?.provider}
        />
        <InputField
          key="label"
          name="label"
          label="Label"
          type="text"
          validationRules={{ required: "Ce champ est requis" }}
        />

        {Object.entries(fieldMappingsData).map(([entityName, descriptor]) => (
          <CheckboxField
            key={`checkbox.${entityName}`}
            name={`checkbox-${entityName}`}
            label={tReport(`report.entities.${entityName}`)}
            checked={checkedEntities[entityName]}
            onChange={() => handleCheckboxChange(entityName)}
            value=""
          />
        ))}

        {Object.entries(fieldMappingsData).filter(([entityName]) => checkedEntities[entityName] ?? false).map(([entityName, descriptor]) => (
          <fieldset key={entityName}>
            <legend>{tReport(`report.entities.${entityName}`)}</legend>
            <div className="form-group-flex">
              <CheckboxField
                key={`checkbox.${entityName}.field.${descriptor.keyIdentifier.field}`}
                name={`checkbox-fieldMappings.${entityName}.${descriptor.keyIdentifier.field}`}
                checked={checkedEntities[entityName] ?? false}
                reverseLabel={true}
                label={tReport(`report.fields.${entityName}.keyIdentifier`)}
                value=""
                disabled={true}
              />
              <InputField
                key={`fieldMappings.${entityName}.keyIdentifier.name`}
                name={`fieldMappings.${entityName}.keyIdentifier.name`}
                type="text"
              />
              <input
                name={`fieldMappings.${entityName}.keyIdentifier.type`}
                type="hidden"
              />
              <input
                name={`fieldMappings.${entityName}.keyIdentifier.field`}
                type="hidden"
              />
              <input
                name={`fieldMappings.${entityName}.keyIdentifier.entity`}
                type="hidden"
              />
            </div>
            {Object.entries(descriptor.fields)
              .filter(([fieldName]) => fieldName !== descriptor.keyIdentifier.field)
              .map(([fieldName, field]) => (
                <>
                  <div className="form-group-flex">
                    <CheckboxField
                      key={`checkbox-${entityName}-${fieldName}`}
                      name={`checkbox-fieldMappings.${entityName}.${fieldName}`}
                      checked={checkedEntities[entityName] === true && (field.required === true || (fieldVisibility[entityName] && fieldVisibility[entityName][fieldName] === true))}
                      reverseLabel={true}
                      onChange={() => handleFieldVisibilityChange(entityName, fieldName)}
                      label={tReport(`report.fields.${entityName}.${fieldName}`)}
                      value=""
                      disabled={field.required ?? false}
                    />
                    {(checkedEntities[entityName] === true && (field.required === true || (fieldVisibility[entityName] && fieldVisibility[entityName][fieldName]))) && (
                      <>
                        <InputField
                          key={`input-${entityName}-${fieldName}`}
                          name={`fieldMappings.${entityName}.fields.${fieldName}.name`}
                          type="text"
                        />
                        <InputField
                          key={`input-${entityName}-${fieldName}-type`}
                          name={`fieldMappings.${entityName}.fields.${fieldName}.type`}
                          type="hidden"
                        />
                        <InputField
                          key={`input-${entityName}-${fieldName}-required`}
                          name={`fieldMappings.${entityName}.fields.${fieldName}.required`}
                          type="hidden"
                        />
                      </>
                    )}
                  </div>
                </>
              ))}
          </fieldset>
        ))}

        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider >
  );
};

export default ReportFormEdit;
