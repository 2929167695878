import { ApiService, request } from "./generic/apiService";
import { ReportInterface } from "../interfaces/ReportInterface";
import { ImportInterface, importRequestInterface } from "../interfaces/ImportInterface";
import { formatDateToLocalISO } from "../utils/generic/dateUtils";

export class ApiImportService extends ApiService<importRequestInterface, importRequestInterface> {
  constructor() {
    super(`/import`);
  }

  requestImport = async (file: File, report: ReportInterface): Promise<importRequestInterface | null> => {
    const formData = new FormData();
    formData.append('file', file);

    formData.append('reportId', report.id.toString());

    try {
      let response = await request({
        url: `${this.basePath}/request`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  };

  import = async (file: File, report: ReportInterface, startDate?: Date, endDate?: Date): Promise<importRequestInterface | null> => {
    const formData = new FormData();
    formData.append('file', file);

    formData.append('reportId', report.id.toString());

    if(startDate && endDate){
      formData.append('startDate', formatDateToLocalISO(startDate));
      formData.append('endDate', formatDateToLocalISO(endDate));
    }
    try {
      let response = await request({
        url: `${this.basePath}/`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  retry = async (importEntity: ImportInterface): Promise<ImportInterface | null> => {

    try {
      let response = await request({
        url: `${this.basePath}/${importEntity.id}/retry`,
        method: 'PUT',
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  stop = async (importEntity: ImportInterface): Promise<ImportInterface | null> => {

    try {
      let response = await request({
        url: `${this.basePath}/${importEntity.id}/stop`,
        method: 'PUT',
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  async importPartners(formData: FormData): Promise<void> {
    const url = `${this.basePath}/partners`;
    await request({
      url,
      method: "POST",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}
