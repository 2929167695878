import React, { useState } from "react";
import { PartnerInterface } from "../../../../interfaces/PartnerInterface";
import DateRangeForm from "../../../generic/Form/DateRangeForm";
import CheckboxField from "../../../generic/Form/Fields/Checkbox";
import { FormProvider, useForm } from "react-hook-form";

interface Props {
  partner: PartnerInterface | null;
  simulate: boolean;
  onSubmit: (partner: PartnerInterface | null, startDate: Date, endDate: Date, simulate: boolean, force: boolean) => void;
}

const AutomationSummaryFormLaunch: React.FC<Props> = ({ onSubmit, partner, simulate }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [force, setForce] = useState<boolean>(false);
  
  const methods = useForm();

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (startDate && endDate) {
      onSubmit(partner, startDate, endDate, simulate, force);
    }
  };

  return (
    <div className="modal-dateRange-container">
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <DateRangeForm
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            startDateLabel="Date de début"
            endDateLabel="Date de fin"
          />
          <CheckboxField
            key={`force`}
            name={`force`}
            label={'force'}
            checked={force}
            onChange={() => {setForce(!force)}}
            value=""
          />
          <div className="buttons-container">
            <button type="submit">Submit</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AutomationSummaryFormLaunch;
