import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportsTable from "./ReportsTable";
import ReportPushModal from "./Modals/ReportPushModal";
import DeleteConfirmationModal from "../generic/Modal/DeleteConfirmationModal";
import { ReportInterface } from "../../interfaces/ReportInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiService } from "../../services/generic/apiService";
import { ApiImportService } from "../../services/apiImportService";
import { useAlert } from "../../context/generic/AlertContext";

interface ReportListProps {
}

const ReportsList: React.FC<ReportListProps> = ({ }) => {
  const apiService = new ApiService<ReportInterface, ReportInterface>('/reports');
  const apiImportService = new ApiImportService();
  const [isReportPushModalOpen, setIsReportPushModalOpen] = useState(false);
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportInterface | null>(null);
  const navigate = useNavigate();

  const { showAlert, closeAlert } = useAlert();

  const getReports = async () => {
    setReports(await apiService.get());
  }

  useEffect(() => {
    getReports();
  }, []);

  const { submitEntity, deleteEntity } = useEntityManager<ReportInterface, ReportInterface>({ apiService, callback: getReports });

  const openReportPushModal = useCallback((report?: ReportInterface) => {
    setSelectedReport(report || null);
    setIsReportPushModalOpen(true);
  }, []);

  const closeReportPushModal = useCallback(() => {
    setIsReportPushModalOpen(false);
    setSelectedReport(null);
  }, []);

  const openDeleteConfirmationModal = useCallback((report: ReportInterface) => {
    setSelectedReport(report);
    setIsDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    setSelectedReport(null);
  }, []);

  const handleSubmit = useCallback((formData: any) => {
    if (selectedReport) {
      submitEntity(selectedReport, formData);
    } else {
      submitEntity(null, formData);
    }
    closeReportPushModal();
  }, [submitEntity, selectedReport]);

  const handleDelete = useCallback(() => {
    if (selectedReport) {
      deleteEntity(selectedReport.id);
    }
    closeDeleteConfirmationModal();
  }, [deleteEntity, selectedReport]);

  const handleUpload = useCallback(async (file: File, report: ReportInterface, startDate?: Date, endDate?: Date) => {
    const alertId = showAlert("upload du fichier en cours...", "warning", -1);
    try {
      await apiImportService.import(file, report, startDate, endDate);
      showAlert("Fichier téléchargé, son contenu est en cours d'analyse", "success");
      navigate("/imports");
    }
    catch (error) {
      showAlert("Impossible de télécharger le fichier", "error");
    }
    finally {
      closeAlert(alertId);
    }
  }, []);

  return (
    <>
      <button onClick={() => openReportPushModal()}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <ReportsTable
        reports={reports}
        onEdit={openReportPushModal}
        onDelete={openDeleteConfirmationModal}
        onUpload={handleUpload}
      />
      <ReportPushModal
        isOpen={isReportPushModalOpen}
        onClose={closeReportPushModal}
        onSubmit={handleSubmit}
        report={selectedReport}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onDelete={handleDelete}
        confirmationMessage="Are you sure you want to delete this report?"
      />
    </>
  );
};

export default ReportsList;
