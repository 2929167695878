import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import ErrorNotFound from "./pages/Error/404";
import { Routes, Route } from "react-router-dom";
import Logout from "./components/generic/Logout";
import MainLayout from "./components/Layout/MainLayout";
import LoginLayout from "./components/Layout/LoginLayout";
import { useAuth } from "./context/generic/AuthContext";
import ResetPassword from "./pages/Login/ResetPassword";
import Users from "./pages/Admin/Users/Users";
import ChangePassword from "./pages/Users/ChangePassword";
import Partners from "./pages/Partners/Partners";
import Shops from "./pages/Shops/Shops";
import Reports from "./pages/Reports/Reports";
import CreatePassword from "./pages/Login/CreatePassword";
import Imports from "./pages/Imports/Imports";
import Orders from "./pages/Orders/Orders";
import ShopPerformances from "./pages/ShopPerformances/ShopPerformances";
import Summary from "./pages/Automations/Summary/Summary";
import Automations from "./pages/Automations/Automations";
import EmailConfigurations from "./pages/EmailConfigurations/EmailConfigurations";
import ProblematicSchedules from "./pages/Automations/ProblematicSchedules/ProblematicSchedules";

function RoutingApp() {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Routes conditionnelles pour l'authentification */}
      {isAuthenticated ? (
        <Route element={<MainLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/shops" element={<Shops />} />
          <Route path="/email/configurations" element={<EmailConfigurations />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/imports" element={<Imports />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/shops/performances" element={<ShopPerformances />} />
          <Route path="/automations" element={<Automations />} />
          <Route path="/automations/summary" element={<Summary />} />
          <Route path="/automations/problematic-schedules" element={<ProblematicSchedules />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<ErrorNotFound />} />
        </Route>
      ) : (
        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Route>
      )}

      {/* Routes toujours accessibles */}
      <Route element={<LoginLayout />}>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/create-password" element={<CreatePassword />} />
      </Route>
    </Routes>
  );
}

export default RoutingApp;
