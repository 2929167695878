import React from "react";
import Table from "../../generic/Table/Table";
import { UserInterface } from "../../../interfaces/UserInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../context/generic/AuthContext";

interface UsersTableProps {
  users: UserInterface[];
  onEdit: (user: UserInterface) => void;
  onDelete: (user: UserInterface) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  onEdit,
  onDelete,
}) => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const headers = [
    "Email",
    "Login",
    "Rôles",
    "Action",
  ];

  const userData = users.map((user) => ({
    Email: user.email,
    Login: user.login,
    Rôles: user.roles.join(","),
    Action: user.id ? (
      <>
        {isAdmin && (
          <>
            <button onClick={() => onEdit(user)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn-delete" onClick={() => onDelete(user)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        )}
      </>
    ) : (
      <></>
    ),
  }));

  return (
    <Table
      headers={headers}
      columnWidths={["auto", "auto", "auto", "7em"]}
      headerAlignments={["left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "center"]}
      data={userData}
    />
  );
};

export default UsersTable;
