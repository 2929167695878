import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../../generic/Modal/Modal";
import { UserInterface } from "../../../../interfaces/UserInterface";
import UserFormEdit from "../Forms/UserFormEdit";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  user?: UserInterface | null;
}

const UserPushModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  user,
}) => {
  const methods = useForm();

  React.useEffect(() => {
    methods.reset({
      email: !user ? "" : user.email,
      login: !user ? "" : user.login,
    });
  }, [user]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        user ? "Modifier un utilisateur" : "Ajouter un utilisateur"
      }
      size="large"
    >
      <UserFormEdit user={user} onSubmit={onSubmit} />
    </Modal>
  );
};

export default UserPushModal;
