import React from "react";
import Modal from "../../../generic/Modal/Modal";
import { PartnerInterface } from "../../../../interfaces/PartnerInterface";
import PartnerFormEdit from "../../Summary/Forms/AutomationSummaryFormLaunch";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (partner: PartnerInterface | null, startDate: Date, endDate: Date, simulate: boolean, force:boolean) => void;
  partner: PartnerInterface | null;
  simulate: boolean;
}

const AutomationPlayConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  partner,
  simulate,
}) => {
  const action = simulate === true ? "Simuler" : "Exécuter";
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        action + (partner ?  " pour le partenaire " + partner.name : " pour tous les partenaires")
      }
      size="large"
    >
      <PartnerFormEdit partner={partner} onSubmit={onSubmit} simulate={simulate} />
    </Modal>
  );
};

export default AutomationPlayConfirmationModal;
