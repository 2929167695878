import React from "react";
import Table from "../../generic/Table/Table";
import { PartnerInterface, SummaryAutomationExecutionInterface } from "../../../interfaces/PartnerInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faVial, faFileAlt, faStop } from "@fortawesome/free-solid-svg-icons";

interface PartnersTableProps {
  partners: PartnerInterface[];
  canPlay: (partner?: PartnerInterface | null) => boolean;
  onPlay: (partner: PartnerInterface) => void;
  onSimulate: (partner: PartnerInterface) => void;
  onShowLogs: (partner: PartnerInterface) => void;
  onStop: (partner?: PartnerInterface) => void
}

const PartnersTable: React.FC<PartnersTableProps> = ({
  partners,
  canPlay,
  onPlay,
  onSimulate,
  onShowLogs,
  onStop,
}) => {

  const headers = [
    "Nom",
    "Manager",
    "Commentaire",
    "Action",
  ];

  const partnerData = partners.map((partner) => ({
    Nom: partner.name,
    Manager: partner?.manager?.login || "",
    Commentaire: partner.comment,
    Action: partner.id ? (
      <>
      {
        canPlay(partner) && 
        <button onClick={() => onPlay(partner)}>
          <FontAwesomeIcon icon={faPlay} />
        </button>
      }
      {
        !canPlay(partner) &&
          <button className="btn-delete" onClick={() => onStop(partner)}>
            <FontAwesomeIcon icon={faStop} />
          </button>
      }
        <button onClick={() => onSimulate(partner)}>
          <FontAwesomeIcon icon={faVial} />
        </button>
        <button onClick={() => onShowLogs(partner)}>
          <FontAwesomeIcon icon={faFileAlt} />
        </button>
      </>
    ) : (
      <></>
    ),
  }));

  return (
    <Table
      headers={headers}
      columnWidths={["auto", "auto", "10em"]}
      headerAlignments={["left", "left", "center"]}
      contentAlignments={["left", "left", "center"]}
      data={partnerData}
    />
  );
};

export default PartnersTable;
