import React, { useEffect, useState } from "react";
import Modal from "../../../generic/Modal/Modal";
import { PartnerInterface } from "../../../../interfaces/PartnerInterface";
import { LogsInterface } from "../Interfaces/LogsInterface";
import AutomationLogsTable from "../AutomationLogsTable";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onForce: (executionLog: LogsInterface) => void;
  partner: PartnerInterface | null;
  callBack: (page: number, items: number, partner: PartnerInterface) => Promise<LogsInterface[]>;
}

const AutomationShowLogsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onForce,
  partner,
  callBack,
}) => {
  const [executionLogs, setExecutionLogs] = useState<LogsInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLogs = async () => {
      if (partner) {
        setLoading(true);
        const logs = await callBack(1, 10, partner);
        setExecutionLogs(logs);
        setLoading(false);
      }
    };

    fetchLogs();
  }, [partner]);

  return (
    partner && 
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        "Logs d'exécution pour le partenaire " + partner.name
      }
      size="xlarge"
    >
      <>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <AutomationLogsTable executionLogs={executionLogs} onForce={onForce} />
        )}
      </>
    </Modal>
  );
};

export default AutomationShowLogsModal;
