import { Link } from "react-router-dom";
import { useMenu } from "../../context/MenuContext";
import { useAuth } from "../../context/generic/AuthContext";
import "./sidebar.css";

const SideBar = () => {
  const { isSidebarOpen, toggleSidebar } = useMenu();
  const { user } = useAuth();

  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const handleLinkClick = () => {
    toggleSidebar();
  };

  return (
    <div id="sidebar" className={isSidebarOpen ? "" : "hidden"}>
      <div className="menu-section">
        <Link to="/dashboard" className="navItem" onClick={handleLinkClick}>
          Dashboard
        </Link>
        <Link to="/orders" className="navItem" onClick={handleLinkClick}>
          Commandes
        </Link>
        <Link to="/shops/performances" className="navItem" onClick={handleLinkClick}>
          Performances
        </Link>
      </div>
      <div className="menu-section">
        <h3 className="section-title">Paramétrage</h3>
        {isAdmin && (
          <Link to="/admin/users" className="navItem" onClick={handleLinkClick}>
            Utilisateurs
          </Link>
        )}
        <Link to="/partners" className="navItem" onClick={handleLinkClick}>
          Partenaires
        </Link>
        <Link to="/shops" className="navItem" onClick={handleLinkClick}>
          Shops
        </Link>
        <Link to="/email/configurations" className="navItem" onClick={handleLinkClick}>
          Email
        </Link>
        <Link to="/automations" className="navItem" onClick={handleLinkClick}>
          Automatisations
        </Link>
      </div>
      <div className="menu-section">
        <h3 className="section-title">Reporting</h3>
        <Link to="/reports" className="navItem" onClick={handleLinkClick}>
          Reports
        </Link>
        <Link to="/imports" className="navItem" onClick={handleLinkClick}>
          Imports
        </Link>
      </div>
      <div className="menu-section">
        <h3 className="section-title">Automatisations</h3>
        <Link to="/automations/summary" className="navItem" onClick={handleLinkClick}>
          Recaps
        </Link>
        <Link to="/automations/problematic-schedules" className="navItem" onClick={handleLinkClick}>
          Avis
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
