import React, { useCallback, useEffect, useRef, useState } from "react";
import { AutomationExecutionStatusEnum, PartnerInterface, SummaryAutomationExecutionInterface } from "../../../interfaces/PartnerInterface";
import "./Summary.css";
import PartnersList from "../generic/PartnersList";
import AutomationPlayConfirmationModal from "../generic/Modals/AutomationPlayConfirmationModal";
import AutomationShowLogsModal from "../generic/Modals/AutomationShowLogsModal";
import { ApiAutomationService } from "../../../services/apiAutomationService";
import { ApiService } from "../../../services/generic/apiService";
import { MessageNotificationInterface, useNotifications } from "../../../context/NotificationContext";
import { useAlert } from "../../../context/generic/AlertContext";
import { getErrorMessage } from "../../../utils/generic/errorUtils";
import { LogsInterface } from "../generic/Interfaces/LogsInterface";


const AutomationSummaryComponent: React.FC = ({
}) => {

  const [selectedPartner, setSelectedPartner] = useState<PartnerInterface | null>(
    null
  );
  const [selectedManager, setSelectedManager] = useState<string>(
    ""
  );
  const [isAutomationPlayConfirmationModalOpen, setIsAutomationPlayConfirmationModalOpen] = useState<boolean>(
    false
  );
  const [isShowLogsModalOpen, setIsShowLogsModalModalOpen] = useState<boolean>(
    false
  );
  const [isSimulating, setIsSimulating] = useState<boolean>(
    true
  );
  const [globalSummaryAutomationExecution, setGlobalSummaryAutomationExecution] = useState<boolean>(false);

  const [partners, setPartners] = useState<PartnerInterface[]>([]);
  const apiAutomationService = new ApiAutomationService();

  const apiService = new ApiService<PartnerInterface, PartnerInterface>('/partners/with-automation/HEBDO_RECAP');

  const { messages } = useNotifications();
  const latestMessageRef = useRef<MessageNotificationInterface | null>(null);

  const { showAlert } = useAlert();

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage.message !== latestMessageRef.current) {
        latestMessageRef.current = latestMessage.message;
        if (latestMessage.message["task"] === "SYSTEM" && latestMessage.message["section"] === "SUMMARRY_AUTOMATION_GLOBAL" && latestMessage.message["type"] === "success") {
          setGlobalSummaryAutomationExecution(false);
          getPartners();
        }
        if (latestMessage.message["task"] === "SYSTEM" && latestMessage.message["section"] === "SUMMARRY_AUTOMATION" && latestMessage.message["type"] === "success") {
          getPartners();
        }
      }
    }
  }, [messages]);

  const getPartners = async () => {
    let apiPartnerManagerService = new ApiService<PartnerInterface, PartnerInterface>(`/partners/with-automation/HEBDO_RECAP/${selectedManager}`);
    const fetchedPartners = await apiPartnerManagerService.get();
    setPartners(fetchedPartners);
  };

  const handleCloseAutomationPlayConfirmationModal = useCallback(
    () => {
      setIsSimulating(true);
      setSelectedPartner(null);
      setIsAutomationPlayConfirmationModalOpen(false);
    },
    []
  );

  const handleSubmitAutomationPlayConfirmationModal = async (partner: PartnerInterface | null, startDate: Date, endDate: Date, simulate: boolean, force: boolean) => {
    try {
      let lastExecution: SummaryAutomationExecutionInterface | null = await apiAutomationService.summaryLaunch(partner, startDate, endDate, simulate, {force, manager:selectedManager});
      if (!partner) {
        setGlobalSummaryAutomationExecution(true);
      }
    } catch (error: any) {
      showAlert(getErrorMessage(error), "error");
    }
    setIsAutomationPlayConfirmationModalOpen(false);
    getPartners();
  };

  const handleOnPlay = useCallback(
    (partner?: PartnerInterface) => {
      setIsSimulating(false)
      setSelectedPartner(partner ?? null);
      setIsAutomationPlayConfirmationModalOpen(true);
    },
    []
  );

  const handleOnSimulate = useCallback(
    (partner?: PartnerInterface) => {
      setIsSimulating(true);
      setSelectedPartner(partner ?? null);
      setIsAutomationPlayConfirmationModalOpen(true);
    },
    []
  );

  const handleOnShowLogs = useCallback(
    async (partner: PartnerInterface) => {
      setSelectedPartner(partner);
      setIsShowLogsModalModalOpen(true);
    },
    []
  );


  const handleCloseShowLogsModal = useCallback(
    () => {
      setSelectedPartner(null);
      setIsShowLogsModalModalOpen(false);
    },
    []
  );


  const handleForceShowLogsModal = async (executionLog: LogsInterface) => {
    try {
      let lastExecution: SummaryAutomationExecutionInterface | null = await apiAutomationService.summaryForce(executionLog);
    } catch (error: any) {
      showAlert(getErrorMessage(error), "error");
    }
    handleCloseShowLogsModal();
    getPartners();
  };

  const handleCanPlay = (partner?: PartnerInterface | null) => {
    if (!partner) {
      return !globalSummaryAutomationExecution;
    }
    return partner?.lastSummaryAutomationExecution === null || ![AutomationExecutionStatusEnum.RUNNING, AutomationExecutionStatusEnum.PENDING].includes(partner.lastSummaryAutomationExecution.status as AutomationExecutionStatusEnum);
  }


  const handleOnStop = async (partner?: PartnerInterface) => {
    await apiAutomationService.summaryStop(partner, {manager:selectedManager});
    getPartners();
  };
  const transformLogs = (logs: SummaryAutomationExecutionInterface[]): LogsInterface[] => {
    return logs.map(log => ({
      id: log.id,
      date: new Date(log.updatedAt ?? log.createdAt),
      status: log.status,
      params: {
        id: log.id.toString(),
        simulate: log.simulate.toString(),
        startDate: log.startDate,
        endDate: log.endDate,
      },
    }));
  };

  const showLogsCallback = async (page: number, items: number, partner: PartnerInterface) => {
    let logs = await apiAutomationService.getLogs(partner, "summary");
    return transformLogs(logs); // Transform the logs before returning
  };

  return (
    <>
      <PartnersList
        canPlay={handleCanPlay}
        onPlay={handleOnPlay}
        onSimulate={handleOnSimulate}
        onShowLogs={handleOnShowLogs}
        onStop={handleOnStop}
        getPartners={getPartners}
        partners={partners}
        selectedManager={selectedManager}
        setSelectedManager={setSelectedManager}
      />

      <AutomationPlayConfirmationModal
        isOpen={isAutomationPlayConfirmationModalOpen}
        onClose={handleCloseAutomationPlayConfirmationModal}
        onSubmit={handleSubmitAutomationPlayConfirmationModal}
        partner={selectedPartner}
        simulate={isSimulating}
      />

      <AutomationShowLogsModal
        isOpen={isShowLogsModalOpen}
        onClose={handleCloseShowLogsModal}
        onForce={handleForceShowLogsModal}
        partner={selectedPartner}
        callBack={showLogsCallback}
      />
    </>
  );
};

export default AutomationSummaryComponent;
